







import { defineComponent, ref } from '@vue/composition-api'
export default defineComponent({
  name: 'App',
  components: {
    AsyncCharacteristic: () => import('../../async/characteristic.vue')
  },
  setup() {
    const show = ref<boolean>(false)
    const handleSetShow = (state: boolean) => (show.value = state)

    return {
      show,
      handleSetShow
    }
  }
})
