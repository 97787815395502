var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-wrapper"},[_c('div',{staticClass:"title"},[_vm._v("课程资质")]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"card1"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/411591ce-89d6-42b0-a0d2-f478d7fd4175.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("权威检测")]),_c('div',{staticClass:"des"},[_vm._v("教育部首批地方自建平台系统检测认证")])]),_c('div',{staticClass:"card1"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/06f6a7ce-27b9-4497-a088-d6d7fb75fad3.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("权威认可")]),_c('div',{staticClass:"des"},[_vm._v("部分课程资源上架“国家中小学智慧教育平台”")])]),_c('div',{staticClass:"card2"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/6fe8a15e-4187-4999-91a3-43dfd74d3ff0.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("专业合作")]),_c('div',{staticClass:"des"},[_vm._v("与出版社合作共创优质课程")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"card3"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/d16324bc-94c0-4358-9a20-94f9c10e3f7c.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("学术研究")]),_c('div',{staticClass:"des"},[_vm._v("承接国家重点课题")]),_c('div',{staticClass:"des"},[_vm._v("打造课后服务高地")])]),_c('div',{staticClass:"card4"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/9ea062c7-922b-4602-9693-a56ac553719c.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("专家论证")]),_c('div',{staticClass:"des"},[_vm._v("云思课后服务课程体系2.0")]),_c('div',{staticClass:"des"},[_vm._v("专家研讨论证会")])]),_c('div',{staticClass:"card5"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/b5870a96-7a18-4fdf-8a18-83e8ef391e6d.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("原创存证")]),_c('div',{staticClass:"des"},[_vm._v("课件、教案、课程")]),_c('div',{staticClass:"des"},[_vm._v("视频存证，三证合一")])]),_c('div',{staticClass:"card6"},[_c('div',{staticClass:"img",style:({
            background: "url(https://zyb-zhike-file.cdnjtzy.com/mis/b5c18f70-9f99-422f-a539-c7096351d4da.png)",
            backgroundSize: 'cover'
          })}),_c('div',{staticClass:"ti"},[_vm._v("安全检测")]),_c('div',{staticClass:"des"},[_vm._v("权威检测")]),_c('div',{staticClass:"des"},[_vm._v("保障材料安全")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }