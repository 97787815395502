





















import { defineComponent } from '@vue/composition-api'
import Banner from './components/banner/index.vue'
import Introduction from './components/introduction/index.vue'
// import Solution from './components/solution/index.vue'
import Manage from './components/manage/index.vue'
import Wave from './components/wave/index.vue'
// import Resource from './components/resource/index.vue'
import Teachers from './components/teachers/index.vue'
import Carousel from './components/carousel/index.vue'
import School from './components/school/index.vue'
import Characteristic from './components/characteristic/index.vue'
// import Advantage from './components/advantage/index.vue'
// import Example from './components/example/index.vue'
import Course from './components/course/index.vue'

export default defineComponent({
  name: 'AfterClassServiceDesk',
  components: {
    Banner,
    Introduction,
    // Solution,
    Manage,
    Wave,
    // Resource,
    Teachers,
    Carousel,
    School,
    Characteristic,
    Course
    // Advantage,
    // Example
  },
  setup() {}
})
