













































import { defineComponent, ref } from '@vue/composition-api'
export default defineComponent({
  name: 'Introduction',
  setup() {
    const carouselRef = ref()
    const activeIndex = ref(0)
    const handleChange = (index) => {
      activeIndex.value = index
    }
    const setActive = (index) => {
      carouselRef.value.setActiveItem(index)
    }
    const cardList = [
      'https://zyb-zhike-file.cdnjtzy.com/mis/388ea8c6-ec5a-4474-87d9-bd191c4d6534.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/4f3ff15b-eb8a-4880-87cb-2d9cc86ab485.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/6f562f97-8f22-4322-9403-0a84991d7275.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/eb39db5d-a11e-49e5-89a6-becf78ebe09e.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/822b6888-6c14-4082-b591-ccffe8d96d0e.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/b4da41e5-d19f-4770-952d-5b27f5c2878f.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/b1238a34-662a-4088-b7a9-e7a125396918.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/79ad316a-98a8-4d77-adf8-85fbbbab0b32.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/b06b0319-5262-45c4-9258-4425fbb18168.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/0125b749-6cea-4826-b518-7bfa146b8823.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/f0e50f0d-6176-48ec-aaf0-5cee1c71ee8b.png',
      'https://zyb-zhike-file.cdnjtzy.com/mis/b4fd834c-4583-45db-b2cf-0eed7202b8b9.png'
    ]
    return {
      carouselRef,
      cardList,
      activeIndex,
      handleChange,
      setActive
    }
  }
})
